import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DiscountTimerService {
  private endTime: Date;
  private _timeLeft: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  get timeLeft(): Observable<number> {
    return this._timeLeft.asObservable();
  }

  constructor() {
    this.endTime = this.calculateNextResetTime(); // Calculate the next reset time
    this.startTimer();
  }

  private calculateNextResetTime(): Date {
    const now = new Date();
    const nextResetTime = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1); // Midnight of the next day
    return nextResetTime;
  }

  private startTimer() {
    setInterval(() => {
      const now = new Date().getTime();
      const distance = this.endTime.getTime() - now;

      if (distance < 0) {
        this.endTime = this.calculateNextResetTime(); // Reset the endTime
      }
      
      this._timeLeft.next(distance);
    }, 1000);
  }
}
