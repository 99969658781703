
<!-- Hero Section -->
<section id="hero" class="hero section">

    <img [src]="'assets/img/rsz_about_us.jpg'" alt="" data-aos="fade-in">

    <div class="container">
      <h2 >Learning Today,<br>Leading Tomorrow</h2>
      <p >MegaLearn is your way to success</p>
      <div class="d-flex mt-4" >
        <a routerLink="/courses" class="btn-get-started">Get Started</a>
      </div>
    </div>

</section>
<!-- /Hero Section -->


<!-- About Start -->
<div class="container-xxl py-5">
    <div class="container">
        <div class="row g-5">
            <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
                <h1 class="mb-4">Welcome to MegaLearn Academy !</h1>
                <p class="mb-4">MegaLearn Academy was founded to address the challenge of access to online education and to facilitate information sharing. Our mission is to help individuals build careers with internationally recognized certificates that can be shared online.</p>
                <div class="row gy-2 gx-4 mb-4">
                    <div class="col-sm-6">      
                        <p class="mb-0"><i class="fa fa-arrow-right text-primary me-2"></i>Online Courses</p>
                    </div>
                    <div class="col-sm-6">
                        <p class="mb-0"><i class="fa fa-arrow-right text-primary me-2"></i>Shareable Certificates</p>
                    </div>
                </div>
                <button class="button" routerLink="account/login" >Join Now</button>
            </div>
        </div>
    </div>
</div>
<!-- About End -->

<app-home-list></app-home-list>


<!-- Service Start -->
<div class="container-xxl py-5">
    <div class="container">
        <div class="row g-4">
            <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
                <div class="service-item text-center pt-3">
                    <div class="px-2">
                        <i class="bi bi-mortarboard"></i>
                        <h5 class="mb-3">Best Online Courses</h5>
                        <p>Explore our curated selection of online courses tailored specifically learners, designed to empower you with knowledge and skills for success.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.3s">
                <div class="service-item text-center pt-3">
                    <div class="px-2">
                        <i class="bi bi-globe"></i>
                        <h5 class="mb-3">Webinar Organization</h5>
                        <p>Engage with industry experts and thought leaders through our meticulously organized webinars, offering valuable insights and networking opportunities.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.5s">
                <div class="service-item text-center pt-3">
                    <div class="px-2">
                        <i class="bi bi-webcam"></i>
                        <h5 class="mb-3">Video Conference Classes</h5>
                        <p>Experience dynamic and interactive learning from the comfort of your own space with our engaging video conference classes, designed to foster collaboration and real-time interaction.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.7s">
                <div class="service-item text-center pt-3">
                    <div class="px-2">
                        <i class="bi bi-book"></i>
                        <h5 class="mb-3">One-on-One Private Classes</h5>
                        <p>Accelerate your learning journey with tailored guidance from our experienced instructors through our exclusive one-on-one private classes.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Service End -->

<!-- Categories Start -->
<div class="container-xxl py-5 category">
    <div class="container">
        <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 class="section-title bg-white text-center px-3">Categories</h6>
            <h1 class="mb-5">Courses Categories</h1>
        </div>
        <div class="row g-3 catTitle">
            <div class="col-lg-7 col-md-6">
                <div class="row g-3">
                    <div class="col-lg-12 col-md-12 wow zoomIn" data-wow-delay="0.1s">
                        <a class="position-relative d-block overflow-hidden" href="">
                            <img class="img-fluid" [src]="'assets/img/cat-1.jpg'" alt="">
                            <div class="bg-white text-center position-absolute bottom-0 end-0 py-2 px-3" style="margin: 1px;">
                                <h5 class="m-0">Web Design</h5>
                                <small>49 Courses</small>
                            </div>
                        </a>
                    </div>
                    <div class="col-lg-6 col-md-12 wow zoomIn" data-wow-delay="0.3s">
                        <a class="position-relative d-block overflow-hidden" href="">
                            <img class="img-fluid" [src]="'assets/img/cat-2.jpg'" alt="">
                            <div class="bg-white text-center position-absolute bottom-0 end-0 py-2 px-3" style="margin: 1px;">
                                <h5 class="m-0">Graphic Design</h5>
                                <small >49 Courses</small>
                            </div>
                        </a>
                    </div>
                    <div class="col-lg-6 col-md-12 wow zoomIn" data-wow-delay="0.5s">
                        <a class="position-relative d-block overflow-hidden" href="">
                            <img class="img-fluid" [src]="'assets/img/cat-3.jpg'" alt="">
                            <div class="bg-white text-center position-absolute bottom-0 end-0 py-2 px-3" style="margin: 1px;">
                                <h5 class="m-0">Video Editing</h5>
                                <small >49 Courses</small>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-md-6 wow zoomIn" data-wow-delay="0.7s" style="min-height: 350px;">
                <a class="position-relative d-block h-100 overflow-hidden" href="">
                    <img class="img-fluid position-absolute w-100 h-100" [src]="'assets/img/cat-4.jpg'" alt="" style="object-fit: cover;">
                    <div class="bg-white text-center position-absolute bottom-0 end-0 py-2 px-3" style="margin:  1px;">
                        <h5 class="m-0">Online Marketing</h5>
                        <small >49 Courses</small>
                    </div>
                </a>
            </div>
        </div>
    </div>
</div>