import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from '@features/home';
import { AuthGuard } from '@core/index';
import { Role } from '@app/_models';
import { RedirectAdminGuard } from '@core/index';
//import { Title } from 'chart.js';


const accountModule = () => import('@features/accountsModule').then(x => x.AccountsModule);
const adminModule = () => import('@features/admin/admin.module').then(x => x.AdminModule);
const coursesModule = () => import('@features/coursesModule').then(x => x.CoursesModule);
const PM = () => import('@features/accountsModule/profile/profile.module').then(x => x.ProfileModule);
const PaymentModule = () => import('@features/coursesModule/payment').then(x => x.PaymentModule);


const routes: Routes = [
    { path: '', component: HomeComponent, canActivate: [RedirectAdminGuard] , data : { breadcrumb : 'Home'  }},
    { path: 'account', loadChildren: accountModule  },
    { path: 'admin', loadChildren: adminModule, canActivate: [AuthGuard], data: { roles: [Role.Admin] } },
    { path: 'courses', loadChildren: coursesModule , data : { breadcrumb : 'Courses' , title : 'Courses' }},
    { path: 'profile', loadChildren: PM , canActivate : [AuthGuard] , data : { breadcrumb : 'Profile' , title : 'Profile'}},
    { path: 'pay', loadChildren: PaymentModule , canActivate : [AuthGuard] , data : { breadcrumb : 'Payment', title : 'Payment' }},
    

    // otherwise redirect to home
    { path: '**', redirectTo: '' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
