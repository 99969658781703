<button (click)="goBack()" class="back-button">
  <i class="bi bi-arrow-left"></i> Go Back
</button> 

<!-- test ici  -->
<!-- <nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item" *ngFor="let item of items; let last = last" [ngClass]="{ 'active': last }">
      <a *ngIf="!last" [routerLink]="item.url">{{ item.label }}</a>
      <span *ngIf="last">{{ item.label }}</span>
    </li>
  </ol>
</nav>
 -->
<!-- <section class="py-3 py-md-4 py-xl-5 bg-light">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h1 class="h4">eCommerce Dashboard</h1>
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb m-0 fs-7">
            <li class="breadcrumb-item"><a class="link-primary text-decoration-none" href="index.html">Home</a></li>
            <li class="breadcrumb-item active" aria-current="page">Dashboard</li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
</section> -->