<div class="container">
    <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
        <h6 class="section-title bg-white text-center text-primary px-3">Courses</h6>
        <h1 class="mb-5">Our Best Courses</h1>
    </div>
    <div class="row justify-content-center" #courseContainer>
        <div class="col-lg-3 col-md-6 col-sm-6 mb-2" *ngFor="let c of displayedCourses" #courseCard>
            <div class="card course-card">
                <img class="card-img-top" [src]="c.image" (click)="goto(c._id)" style="height: 150px; object-fit: cover;">
                <div class="card-body course-content">
                  <div class="d-flex justify-content-between align-items-center mb-3">
                    <p class="category">{{ c.category.name}}</p>
                    <small class="text-muted">
                      <span class="text-danger">70% Disc. <del>{{c.price}}TND</del></span>
                  </small>
                  </div>
                    <a class="card-title" routerLink="/courses/details/{{c._id}}">{{c.title}}</a>
                    <p class="text-muted">{{c.description | truncate:100}}</p>
                    <div class="d-flex justify-content-between">
                      <small class="text-muted"><i class="bi bi-clock clock-icon me-2"></i>{{c.duration}} Hours</small>
                      <div class="trainer-rank d-flex align-items-center">
                        <i class="bi bi-person user-icon"></i>&nbsp;{{ c.randomNumber }}
                        &nbsp;&nbsp;
                        <i class="bi bi-star star-icon"></i>&nbsp;4.3
                      </div>
                  </div>
                    <div class="d-flex flex-column justify-content-between">

                        <div class="mt-3 d-flex justify-content-center">
                          <button class="button coursebtn" routerLink="/courses/details/{{c._id}}">
                            <i class="bi bi-cart me-2"></i>Buy {{discount(c.price)}} TND</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> 
    <div class="d-flex justify-content-center mt-4">
        <button class="button" routerLink="courses" >More Courses</button>
    </div>
</div>