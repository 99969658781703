import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class CartService {
    private baseUrl = `${environment.apiUrl}/cart`;

    constructor(private http: HttpClient) { }

      addToCart(courseId: string): Observable<any> {
        return this.http.post(`${this.baseUrl}`, { courseId });
      }
    
      getCartItems(): Observable<any[]> {
        return this.http.get<any[]>(`${this.baseUrl}`);
      }

      getCart(): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/get`);
      }
    
      removeCartItem(courseId: string): Observable<any> {
        return this.http.delete(`${this.baseUrl}/${courseId}`);
      }

      clearCart() {
        return this.http.put<any>(`${this.baseUrl}/clear`, []);
      }
}